import * as _propTypes4 from "prop-types";

var _propTypes3 = "default" in _propTypes4 ? _propTypes4.default : _propTypes4;

import * as _react4 from "react";

var _react3 = "default" in _react4 ? _react4.default : _react4;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _propTypes = _propTypes3;

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = _react3;

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var propTypes = {
  d: _propTypes2.default.string.isRequired,
  fill: _propTypes2.default.string.isRequired,
  transformX: _propTypes2.default.number.isRequired,
  transformY: _propTypes2.default.number.isRequired
};
var defaultProps = {};

var QRCodeCell = function QRCodeCell(_ref) {
  var d = _ref.d,
      fill = _ref.fill,
      transformX = _ref.transformX,
      transformY = _ref.transformY;
  return _react2.default.createElement("path", {
    d: d,
    fill: fill,
    transform: "matrix(" + [1, 0, 0, 1, transformX, transformY] + ")"
  });
};

QRCodeCell.propTypes = propTypes;
QRCodeCell.defaultProps = defaultProps;
exports.default = QRCodeCell;
export default exports;